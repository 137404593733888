<template>
  <div>
    <b-card title="Welcome to Daikin Portal 💻">
      <b-card-text>Select a Menu to start </b-card-text>
      <!--<ServiceCard />-->
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
//import ServiceCard from './master/ServiceCard.vue'

export default {
  components: {
    BCard,
    BCardText,
    //ServiceCard,
  },
}
</script>

<style>

</style>
